import { Injectable } from '@angular/core';
import { Asset } from '../models/asset';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Family, FamilyRight, ParentFamily, ParentFamilyRight } from '../models/family';
import { FamilyRightCache, RoleLevelEnum } from '../models/user-right';
import { Company } from '../models/company';
import { Site } from '../models/site';
import { Zone } from '../models/zone';

export enum ObjectStore {
    Assets = 'assets',
    LastUpdatedTime = 'lastUpdatedTime',
    ExpiredBy = 'expiredBy',
    ParentFamilies = 'parentFamilies',
    Families = 'families',
    FamilyRight = 'familyRight',
    Company = 'company',
    Sites = 'sites',
    Zone = 'zone',
}

export const cacheIDB = 'cacheIDB';

@Injectable({
    providedIn: 'root'
})


export class CacheService {
    private db: IDBDatabase | null = null;

    /**
     * Storing cache for the first time
     * @param objectData
     */
    public cacheState(objectData: Asset[] | ParentFamily[] | Family[] | FamilyRight[] | Company[] | Site[] | Zone[], objectType: ObjectStore) {
        const request = indexedDB.open(cacheIDB, 1);

        request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
            this.db = (event.target as IDBOpenDBRequest).result;

            // Create multiple object stores (tables)
            this.db.createObjectStore(ObjectStore.ParentFamilies, { keyPath: 'id' });
            this.db.createObjectStore(ObjectStore.Families, { keyPath: 'id' });
            this.db.createObjectStore(ObjectStore.Assets, { keyPath: 'id' });
            this.db.createObjectStore(ObjectStore.LastUpdatedTime, { keyPath: 'id' });
            this.db.createObjectStore(ObjectStore.ExpiredBy, { keyPath: 'id' });
            this.db.createObjectStore(ObjectStore.FamilyRight, { keyPath: 'id' });
            this.db.createObjectStore(ObjectStore.Company, { keyPath: 'id' });
            this.db.createObjectStore(ObjectStore.Sites, { keyPath: 'id' });
            this.db.createObjectStore(ObjectStore.Zone, { keyPath: 'id' });
        };

        request.onsuccess = (event: Event) => {
            this.db = (event.target as IDBOpenDBRequest).result;
            this.addData(objectType, objectData);

            // Adding timestamp and expiredBy
            const lastUpdatedTime = moment().utcOffset(0).format('YYYY-MM-DDTHH:mm:ss+0000');
            const expiredBy = moment().add(7, 'days').utcOffset(0).format('YYYY-MM-DDTHH:mm:ss+0000');
            const transaction = this.db.transaction([ObjectStore.LastUpdatedTime, ObjectStore.ExpiredBy], 'readwrite');
            const lastUpdatedStore = transaction.objectStore(ObjectStore.LastUpdatedTime);
            const expiredByStore = transaction.objectStore(ObjectStore.ExpiredBy);

            // Update LastUpdatedTime store
            lastUpdatedStore.get(objectType).onsuccess = (event: Event) => {
                const data = (event.target as IDBRequest).result;
                if (data) {
                    data.name = lastUpdatedTime;
                    lastUpdatedStore.put(data);
                } else {
                    lastUpdatedStore.put({ id: objectType, name: lastUpdatedTime });
                }
            };

            // Update ExpiredBy store
            expiredByStore.get(objectType).onsuccess = (event: Event) => {
                const data = (event.target as IDBRequest).result;
                if (data) {
                    data.name = expiredBy;
                    expiredByStore.put(data);
                } else {
                    expiredByStore.put({ id: objectType, name: expiredBy });
                }
            };
        };

        request.onerror = (event: Event) => {
            console.error('IndexedDB error:', (event.target as IDBOpenDBRequest).error);
        };
    }

    /**
     * retrieving Cache from the indexDB
     * @returns
     */
    public retrieveCache(): Observable<{ [key: string]: { name: string, isExpired: boolean } }> {
        return new Observable<{ [key: string]: { name: string, isExpired: boolean } }>((observer) => {
            indexedDB.databases()
                .then((databases) => {
                    const cacheExists = databases.some(db => db.name === cacheIDB);
                    if (!cacheExists) {
                        console.error(`IndexedDB database '${cacheIDB}' does not exist.`);
                        observer.error('Cache does not exist.');
                        return;
                    }
                    return indexedDB.open(cacheIDB, 1);
                })
                .then((request) => {
                    if (!request) { return; }
                    request.onerror = (event) => {
                        console.error('IndexedDB request error:', request.error);
                        observer.error(request.error);
                    };
                    request.onsuccess = (event: Event) => {
                        const db = (event.target as IDBOpenDBRequest).result;

                        const objectTypes = [
                            ObjectStore.FamilyRight,
                            ObjectStore.Zone,
                            ObjectStore.ParentFamilies,
                            ObjectStore.Families,
                            ObjectStore.Assets,
                            ObjectStore.Company,
                            ObjectStore.Sites
                        ];

                        const promises = objectTypes.map(objectType => {
                            return new Promise<{ name: string, isExpired: boolean }>((resolve, reject) => {
                                const transaction = db.transaction([ObjectStore.ExpiredBy, ObjectStore.LastUpdatedTime], 'readonly');

                                const expiredByObjectStore = transaction.objectStore(ObjectStore.ExpiredBy);
                                const getExpiredByRequest = expiredByObjectStore.get(objectType);

                                getExpiredByRequest.onsuccess = (eventExpired) => {
                                    const expiredDate = getExpiredByRequest.result?.name;
                                    const isExpired = expiredDate ? new Date(expiredDate) <= new Date() : true;

                                    if (isExpired) {
                                        resolve({ name: '', isExpired: true });
                                    } else {
                                        const lastUpdatedTimeObjectStore = transaction.objectStore(ObjectStore.LastUpdatedTime);
                                        const getLastRefreshRequest = lastUpdatedTimeObjectStore.get(objectType);

                                        getLastRefreshRequest.onsuccess = (eventLastRefresh) => {
                                            const lastRefreshTime = getLastRefreshRequest.result?.name || '';
                                            resolve({ name: lastRefreshTime, isExpired: false });
                                        };
                                        getLastRefreshRequest.onerror = (eventLastRefreshError) => {
                                            reject(getLastRefreshRequest.error);
                                        };
                                    }
                                };
                                getExpiredByRequest.onerror = (eventExpiredError) => {
                                    reject(getExpiredByRequest.error);
                                };
                            });
                        });

                        Promise.all(promises)
                            .then(results => {
                                const cache = {};
                                results.forEach((result, index) => {
                                    cache[objectTypes[index]] = {
                                        name: result.name,
                                        isExpired: result.isExpired
                                    };
                                });
                                observer.next(cache);
                                observer.complete();
                            })
                            .catch(error => {
                                console.error("Error occurred while retrieving cache:", error);
                                this.clearCacheAndFetchFullData()
                                    .then(() => {
                                        observer.error('Cache cleared, fetching full data.');
                                    })
                                    .catch(clearError => {
                                        console.error('Failed to clear cache:', clearError);
                                        observer.error('Failed to clear cache:');
                                    });
                            });
                    };

                    request.onblocked = (event) => {
                        console.error('IndexedDB request blocked.');
                        observer.error('IndexedDB request blocked.');
                    };

                    request.onupgradeneeded = (event) => {
                        console.error('IndexedDB upgrade needed.');
                        observer.error('IndexedDB upgrade needed.');
                    };
                })
                .catch((error) => {
                    console.error("Error occurred while retrieving cache:", error);
                    observer.error(error);
                });
        });
    }


    public clearCacheContext(): Promise<void> {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(cacheIDB);

            request.onupgradeneeded = (event) => {
                const db = (event.target as IDBOpenDBRequest).result;

                if (db.objectStoreNames.contains(ObjectStore.Assets)) {
                    db.deleteObjectStore(ObjectStore.Assets);
                }
                if (db.objectStoreNames.contains(ObjectStore.Families)) {
                    db.deleteObjectStore(ObjectStore.Families);
                }
                if (db.objectStoreNames.contains(ObjectStore.ParentFamilies)) {
                    db.deleteObjectStore(ObjectStore.ParentFamilies);
                }
            };

            request.onsuccess = (event) => {
                const db = (event.target as IDBOpenDBRequest).result;

                const transaction = db.transaction([ObjectStore.LastUpdatedTime, ObjectStore.ExpiredBy], 'readwrite');

                const lastRefreshStore = transaction.objectStore(ObjectStore.LastUpdatedTime);
                const expiryStore = transaction.objectStore(ObjectStore.ExpiredBy);

                lastRefreshStore.delete(ObjectStore.Assets);
                lastRefreshStore.delete(ObjectStore.Families);
                lastRefreshStore.delete(ObjectStore.ParentFamilies);

                expiryStore.delete(ObjectStore.Assets);
                expiryStore.delete(ObjectStore.Families);
                expiryStore.delete(ObjectStore.ParentFamilies);

                transaction.oncomplete = () => {
                    resolve();
                };

                transaction.onerror = (event) => {
                    reject(event);
                };
            };

            request.onerror = (event) => {
                reject(event);
            };
        });
    }



    public clearCacheAndFetchFullData(clearContext = false): Promise<void> {
        return new Promise((resolve, reject) => {
            if (clearContext) {
                // Open the database to clear specific object stores and keys
                const request = indexedDB.open(cacheIDB);

                request.onerror = () => reject(request.error);

                request.onsuccess = () => {
                    const db = request.result;
                    const { objectStoreNames } = db;
                    const storesToClear = Array.from(objectStoreNames).filter(storeName =>
                        [ObjectStore.Assets, ObjectStore.ParentFamilies, ObjectStore.Families].includes(storeName as ObjectStore)
                    );

                    const transaction = db.transaction(storesToClear, 'readwrite');
                    storesToClear.forEach(storeName => {
                        transaction.objectStore(storeName).clear();
                    });

                    transaction.oncomplete = () => {
                        // Now clear keys from expiredBy and lastUpdatedTime stores
                        if (db.objectStoreNames.contains(ObjectStore.ExpiredBy) || db.objectStoreNames.contains(ObjectStore.LastUpdatedTime)) {
                            const cleanupTransaction = db.transaction([ObjectStore.ExpiredBy, ObjectStore.LastUpdatedTime], 'readwrite');
                            const expiredByStore = cleanupTransaction.objectStore(ObjectStore.ExpiredBy);
                            const lastUpdatedTimeStore = cleanupTransaction.objectStore(ObjectStore.LastUpdatedTime);

                            // Delete the corresponding keys from expiredBy and lastUpdatedTime
                            [ObjectStore.Assets, ObjectStore.ParentFamilies, ObjectStore.Families].forEach(key => {
                                expiredByStore.delete(key);
                                lastUpdatedTimeStore.delete(key);
                            });

                            cleanupTransaction.oncomplete = () => {
                                db.close();
                                resolve();
                            };
                            cleanupTransaction.onerror = () => {
                                db.close();
                                reject(cleanupTransaction.error);
                            };
                        } else {
                            db.close();
                            resolve();
                        }
                    };

                    transaction.onerror = () => {
                        db.close();
                        reject(transaction.error);
                    };
                };
            } else {
                const attemptDeleteDatabase = (retryCount = 0) => {
                    const request = indexedDB.deleteDatabase('cacheIDB');

                    request.onsuccess = () => {
                      console.log('Database deleted successfully');
                      resolve();
                    };

                    request.onerror = (event) => {
                      console.error('Error deleting database:', event);

                      if (retryCount < 3) { // Retry up to 3 times
                        console.log(`Retrying... attempt ${retryCount + 1}`);
                        setTimeout(() => attemptDeleteDatabase(retryCount + 1), 1000); // Retry after 1 second
                      } else {
                        console.error('Failed to delete database after multiple attempts');
                        resolve();
                      }
                    };

                    request.onblocked = () => {
                      console.warn('Database delete request blocked');
                      resolve();
                    };
                  };

                attemptDeleteDatabase();
            }
        });
    }





    /**
     * On Updation of an asset in by current user, Or if delta is returning any asset(s) that has been update in the db.
     * Updating them into indexDB
     * @param updatedData
     * @param delta
     * @returns
     */
    public updateCache(updatedData: Asset[] | ParentFamily[] | Family[] | FamilyRightCache[] | ParentFamilyRight[] | Company[] | Site[] | Zone[], objectType: ObjectStore, delta?: boolean): Observable<Asset[] | ParentFamily[] | Family[] | FamilyRightCache[] | ParentFamilyRight[] | Company[] | Site[] | Zone[]> {
        return new Observable<Asset[] | ParentFamily[] | Family[] | FamilyRightCache[] | ParentFamilyRight[] | Company[] | Site[] | Zone[]>(observer => {
            const request = indexedDB.open(cacheIDB, 1);
            request.onsuccess = (event: Event) => {
                this.db = (event.target as IDBOpenDBRequest).result;

                if (updatedData) {
                    if (updatedData?.length > 0) {
                        this.updateData(objectType, updatedData);
                    }
                }

                if (delta) {
                    const currentTime = moment().utcOffset(0).format('YYYY-MM-DDTHH:mm:ss+0000');
                    const expiredTime = moment().add(7, 'days').utcOffset(0).format('YYYY-MM-DDTHH:mm:ss+0000');

                    // Update LastUpdatedTime
                    const lastUpdatedObjectStore = this.db.transaction([ObjectStore.LastUpdatedTime], 'readwrite').objectStore(ObjectStore.LastUpdatedTime);
                    lastUpdatedObjectStore.put({ id: objectType, name: currentTime });

                    // Update ExpiredBy
                    const expiredByObjectStore = this.db.transaction([ObjectStore.ExpiredBy], 'readwrite').objectStore(ObjectStore.ExpiredBy);
                    expiredByObjectStore.put({ id: objectType, name: expiredTime });

                }

                const data: IDBRequest<any[]> = this.db.transaction([objectType], 'readwrite').objectStore(objectType).getAll();

                data.onsuccess = () => {
                    observer.next(data.result);
                    observer.complete();
                };
            };

            request.onerror = (event) => {
                observer.error(request.error);
            };
        });
    }



    /**
    * On deletion of an asset in by current user, Or if delta is returning any asset(s) that has been deleted from the db.
     * removing them from indexDB
     * @param deletedIds
     * @returns
     */
    public deleteFromCache(deletedIds: Asset[] | ParentFamily[] | Family[] | FamilyRightCache[] | Company[] | Site[] | Zone[], objectType: ObjectStore): Observable<Asset[] | ParentFamily[] | Family[] | FamilyRightCache[] | Company[] | Site[] | Zone[]> {
        return new Observable<Asset[]>(observer => {
            const request = indexedDB.open(cacheIDB, 1);

            request.onsuccess = (event: Event) => {
                this.db = (event.target as IDBOpenDBRequest).result;
                const transaction = this.db.transaction([objectType], 'readwrite');
                const store = transaction.objectStore(objectType);

                deletedIds.forEach(Item => {
                    const deleteRequest = store.delete(Item.id);
                    deleteRequest.onerror = (event) => {
                        console.error(`Error deleting item with ID ${Item.id}:`, deleteRequest.error);
                    };
                });

                transaction.oncomplete = () => {
                    const objectTransaction = this.db.transaction([objectType], 'readonly');
                    const objectStore = objectTransaction.objectStore(objectType);
                    const getRequest = objectStore.getAll();
                    getRequest.onsuccess = (event) => {
                        observer.next(getRequest.result);
                        observer.complete();
                    };

                };

                transaction.onerror = (event) => {
                    observer.error(transaction.error);
                };
            };

            request.onerror = (event) => {
                observer.error(request.error);
            };
        });
    }

    private addData(tableName: string, objectData: any) {
        if (!this.db) {
            console.error('Database not initialized.');
            return;
        }

        const transaction = this.db.transaction([tableName], 'readwrite');
        const objectStore = transaction.objectStore(tableName);

        if (Array.isArray(objectData)) {
            objectData.forEach((data) => objectStore.put(data));
        } else {
            Object.values(objectData).forEach((data) => objectStore.put(data));
        }
    }
    private updateData(tableName: string, updatedData: Asset[] | ParentFamily[] | Family[] | FamilyRightCache[] | ParentFamilyRight[] | Company[] | Site[]) {
        if (!this.db) {
            console.error('Database not initialized while update.');
            return;
        }

        const transaction = this.db.transaction([tableName], 'readwrite');
        const objectStore = transaction.objectStore(tableName);

        if (tableName === ObjectStore.FamilyRight) {
            updatedData.forEach(element => {
                const parentFamilyId = (element as any).parentFamilyId;
                const Id = (element as any).id;

                if (parentFamilyId === undefined) {
                    let found = false;
                    const cursorRequest = objectStore.openCursor();

                    cursorRequest.onsuccess = (event) => {
                        const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result;

                        if (cursor) {
                            const record = cursor.value;

                            if (record.parentFamilyId === Id) {
                                record.level = RoleLevelEnum.basic;
                                objectStore.put(record);
                                found = true;
                            }
                            cursor.continue();
                        } else {
                            if (!found) {
                                objectStore.add(element);
                            }
                        }
                    };

                    cursorRequest.onerror = (event) => {
                        console.error('Cursor request failed:', (event.target as IDBRequest).error);
                    };
                } else {
                    const getRequest = objectStore.get((element as any).id);
                    getRequest.onsuccess = (event) => {
                        const existingData = getRequest.result;

                        if (existingData) {
                            objectStore.put(element);
                        } else {
                            objectStore.add(element);
                        }
                    };

                    getRequest.onerror = (event) => {
                        console.error('Get request failed:', (event.target as IDBRequest).error);
                    };
                }
            });
        } else {
            updatedData.forEach(element => {
                const getRequest = objectStore.get((element as any).id);
                getRequest.onsuccess = (event) => {
                    const existingData = getRequest.result;

                    if (existingData) {
                        objectStore.put(element);
                    } else {
                        objectStore.add(element);
                    }
                };
                getRequest.onerror = (event) => {
                    // add error log
                    console.error('Get request failed:', (event.target as IDBRequest).error);
                };
            });
        }
    }
}
